import React, { useState, useEffect, useContext } from 'react'
import faq from '../Component/Image/Faq.png'
import third from '../Component/Image/third.jpg'
import second from '../Component/Image/second.jpg'
import first from '../Component/Image/first.jpg'
import CardImage from '../Component/Image/1jpg.jpg'
import CardImage2 from '../Component/Image/(2).jpg'
import CardImage3 from '../Component/Image/(3).jpg'
import CardImage4 from '../Component/Image/(4).jpg'
import CardImage5 from '../Component/Image/(3).jpg'
import CardImage6 from '../Component/Image/1jpg.jpg'
import deepak from '../Component/Image/deepak.png'
// import { FaFacebook } from " react-icons/fa"; 
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";

import '../App.css'
import Header from './Header'
import Footer from './Footer'
import { Carousel } from 'react-responsive-carousel'
import { useNavigate, useParams } from 'react-router-dom'
import Context from '../Context/Context'
import { FaYoutube } from 'react-icons/fa6'


const Home = () => {

  // const { id } = useParams();

  const { user, setUser } = useContext(Context);

  const navigate = useNavigate();
  const [Data, setData] = useState();
  const [basicCategories, setbasicCategories] = useState([]);
  const [blogs, setblogs] = useState([]);
  const [smallMirrors, setsmallMirrors] = useState([]);
  const [trendingCategory, settrendingCategory] = useState();
  const [count, setcount] = useState(0);
  const [Count1, setCount1] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundImage1, setBackgroundImage1] = useState('');
  const [Right, setRight] = useState('');
  const [left, setleft] = useState('');
  const [RightText, setRightText] = useState('');
  const [leftText, setleftText] = useState('');
  const [RightTextSlug, setRightTextSlug] = useState('');
  const [leftTextSlug, setleftTextSlug] = useState('');
  // console.log(backgroundImage,count, "background");

  const handleImageClick = (imageUrl) => {
    setBackgroundImage(imageUrl);
  };

  const handleImageClick1 = (imageUrl) => {
    setBackgroundImage1(imageUrl);
  };

  const get_Api = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://api.placestovisitindia.com/api/getHomeSettings/${1}`, requestOptions);
      const result = await response.json();
      console.log(result);
      window.scrollTo(0, 0);
      setData(result?.homeSetting);
      setbasicCategories(result?.basicCategories);
      setblogs(result?.blogs);
      setsmallMirrors(result?.smallMirrors);
      settrendingCategory(result?.trendingCategory);
      const random = Math.floor(Math.random() * result?.blogs?.length);
      const random1 = Math.floor(Math.random() * result?.trendingCategory?.posts?.length);
      console.log(random, random1, "============>random");
      handleImageClick(result?.blogs[random]?.images[0]?.img_path);
      handleImageClick1(result?.trendingCategory?.posts[random1]?.images[0]?.img_path);
      setcount(random);
      setCount1(random1);
      setRight(result?.post_right[0]?.images[0]?.img_path);
      setleft(result?.post_left[0]?.images[0]?.img_path);
      setRightText(result?.post_right[0]?.post_title);
      setleftText(result?.post_left[0]?.post_title);
      setRightText(result?.post_right[0]?.post_title);
      setleftText(result?.post_left[0]?.post_title);
      setRightTextSlug(result?.post_right[0]?.post_slug);
      setleftTextSlug(result?.post_left[0]?.post_slug)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setcount(parseInt(await localStorage.getItem("data"))); // Parse count as integer
      await get_Api();
    };
    fetchData();
  }, []);


  const divStyle = {
    backgroundImage: `url(${backgroundImage})`,
    width: "100%",
    height: "auto",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "8px"
  };

  const divStyle1 = {
    backgroundImage: `url(${backgroundImage1})`,
    width: "100%",
    height: "auto",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "8px"
  };

  const divStyle2 = {
    backgroundImage: `url(${Right})`,
    width: "100%",
    height: "auto",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "8px"
  };

  const divStyle3 = {
    backgroundImage: `url(${left})`,
    width: "100%",
    height: "auto",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "8px"
  };

  const renderCustomIndicator = (clickHandler, isSelected, index, label) => {
    const indicatorStyle = {
      width: '20px',
      height: '20px',
      margin: '0 5px',
      background: isSelected ? '#fff' : '#888', // Change the background color based on selection
      borderRadius: '10%',
      cursor: 'pointer',
      display: 'inline-block',
    };

    return (
      <li key={index} style={indicatorStyle} onClick={clickHandler} role="button" tabIndex={0} title={`${label} ${index + 1}`} />
    );
  };

  return (
    <>
      <Header />
      <div className='container-fluid mt-3'>
        <div className='row'>
          <div className='col-lg-12 col-sm-12 mt-5 p-0'>

            <Carousel autoPlay infiniteLoop interval={3000} showThumbs={false} showIndicators={true} renderIndicator={renderCustomIndicator}>
              {
                Data?.images?.map((res) => {
                  return (
                    <>
                      {
                        res?.image_type == "header" ? <div>
                          <img src={res.img_path} alt='img' style={{ height: "100%", }} />
                          <h1 style={{ backgroundColor: 'transparent', fontSize: '28px', }} className="legend">{Data?.post_title} <br /></h1>
                        </div> : null
                      }

                    </>
                  )
                })
              }

            </Carousel>
            {/* <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel"  >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src={third} class="d-block w-100 demo p-0    " alt="..." style={{ height: '80vh' }} />
                </div>
                <div class="carousel-item">
                  <img src={first} class="d-block w-100 demo" alt="..." style={{ height: '80vh' }} />
                </div>
                <div class="carousel-item">
                  <img src={second} class="d-block w-100 demo" alt="..." style={{ height: '80vh' }} />
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>



      <section>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3 ' style={{ justifyContent: "center", alignItems: "center", border: "3px solid orange", height: '0px' }}> </div>
                <div className='col-sm-12 col-md-6 pooja' style={{ justifyContent: "center", alignItems: "center" }}>
                  <h2 className='Rahul ' style={{
                    whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                    overflow: "hidden",   /* Hides any content that overflows the container */
                    textOverflow: "ellipsis", textShadow: '1.1px 0px 0px #c1c1c1',
                    textTransform: "capitalize"
                  }}>{Data?.upper_title}</h2>
                </div>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid green", height: '0px' }}> </div>
              </div>
              <p style={{
                fontSize: '20px', color: '#646464',
                textShadow: '1.1px 0px 0px #c1c1c1', lineHeight: '38px', letterSpacing: '1px'
              }}>{Data?.upper_desc}</p>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className='container'>
          <div className='row'>
            {/* {basicCategories?.map((res, index) => (
              <div className='col-lg-4 col-md-6 col-sm-12 mt-4' key={index}>
                <div
                  className='card'
                  style={{ borderRadius: 10, overflow: 'hidden', position: 'relative' }}

                  onClick={() => { navigate(`/${res.slug}`); setUser(true); }}
                >
                  <div className='header'>
                    <img src={res.topimages[0]?.img_path} alt='img' style={{ width: '100%', height: "100%", overflow: "hidden", transition: 'transform 0.3s ease' }} onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.1)'; }}
                      onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; }} />
                  </div>
                  <div className='card-body' style={{ backgroundColor: '#d3d3d3' }}>
                    <h4 className='text-center' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>{res?.name}</h4>
                  </div>
                </div>
              </div>
            ))} */}
            {basicCategories?.map((res, index) => (
              <div className='col-lg-4 col-md-6 col-sm-12 mt-4' key={index}>
                <div
                  className='card'
                  style={{
                    borderRadius: 10,
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer'
                  }}
                  onClick={() => { navigate(`/${res.slug}`); setUser(true); }}
                >
                  {/* Image Section */}
                  <div className='header' style={{ position: 'relative' }}>
                    <img
                      src={res.topimages[0]?.img_path}
                      alt='img'
                      style={{
                        width: '100%',
                        height: '100%',
                        transition: 'transform 0.3s ease',
                        objectFit: 'cover'  // Ensures image fits the container properly
                      }}
                      onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.1)'; }}
                      onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}
                    />

                    {/* Text Over Image */}
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',  // Horizontally center the text
                        alignItems: 'end',         // Vertically center the text
                        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent black overlay
                        color: 'white',
                        fontWeight: '700',
                        fontSize: '1.5rem',
                        textShadow: '1px 1px 3px rgba(0, 0, 0, 0.6)',
                        textAlign: 'center',
                        transition: 'color 0.3s ease'  // Smooth transition for color change
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.color = 'yellow';  // Change to desired hover color
                        e.currentTarget.style.transform = 'scale(1.1)';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.color = 'white';
                        e.currentTarget.style.transform = 'scale(1)';   // Revert back to original color
                      }}


                    >
                      <div style={{ marginTop: -30, height: "20%" }}>
                        {res?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}


          </div>
        </div>
      </section>



      <section>
        <div className='container mt-5'>
          <div className='row'>

            <div className='row'>
              <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid orange", height: '0px' }}> </div>
              <div className='col-sm-12 col-md-6 pooja' style={{ justifyContent: "center", alignItems: "center" }}>
                <h2 className='Rahul' style={{
                  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                  overflow: "hidden",   /* Hides any content that overflows the container */
                  textOverflow: "ellipsis", textShadow: '1.1px 0px 0px #c1c1c1',
                  textTransform: "capitalize"
                }}>{Data?.middle_title}</h2>
              </div>
              <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid green", height: '0px' }}> </div>
            </div>

            {/* <h2 style={{textAlign:'center', fontWeight:'700'}}>Popular Search This Week</h2> */}
            <div className='col-lg-5 col-md-5 col-sm-12 mt-5'>

              <div onClick={() => { navigate(`/blogs/${blogs[count]?.slug}`) }} className='' style={divStyle} >
                <h2 className='pb-4' style={{ paddingTop: '70%',   borderRadius: "8px",textAlign: 'center', color: '#fff', textShadow: '1.1px 0px 0px #c1c1c1', cursor: "pointer", backgroundColor: 'rgba(0, 0, 0, 0.4)' }} onMouseEnter={(e) => {
                  e.currentTarget.style.color = 'yellow';  // Change to desired hover color

                }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'white';

                  }}>{blogs[count]?.name}</h2>
              </div>

            </div>


            <div className='col-lg-7 col-md-7 col-sm-12 mt-5'>
              {blogs?.map((res) => {
                return (
                  <>
                    <h5 onClick={() => { navigate(`/blogs/${res.slug}`) }} className="single-line" style={{
                      fontSize: '25px', color: 'black',
                      lineHeight: '38px', fontWeight: 600, cursor: "pointer",

                    }}>{res.name}</h5>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className='container mt-3 mb-2'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>

              <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid orange", height: '0px' }}> </div>
                <div className='col-sm-12 col-md-6 pooja' style={{ justifyContent: "center", alignItems: "center" }}>
                  <h2 className='Rahul' style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis", textShadow: '1.1px 0px 0px #c1c1c1'
                  }}>{Data?.about_ptvi_title}</h2>
                </div>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid green", height: '0px' }}> </div>
              </div>
              {/* <h2 style={{textAlign:'center', fontWeight:'700'}}>About places to Visit India</h2> */}

              <div dangerouslySetInnerHTML={{ __html: Data?.about_ptvi_desc }} />

              {/* <p style={{
                fontSize: '20px', color: '#646464',
                textShadow: '1.1px 0px 0px #c1c1c1', lineHeight: '38px', letterSpacing: '1px'
              }}>
                {Data?.about_ptvi_desc}
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>

              <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid orange", height: '0px' }}> </div>
                <div className='col-sm-12 col-md-6 pooja' style={{ justifyContent: "center", alignItems: "center" }}>
                  <h2 className='Rahul' style={{
                    whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                    overflow: "hidden",   /* Hides any content that overflows the container */
                    textOverflow: "ellipsis", textShadow: '1.1px 0px 0px #c1c1c1'
                  }}>A Small Mirror  to Real Bharat</h2>
                </div>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid green", height: '0px' }}> </div>
              </div>

              {/* <h2 style={{textAlign:'center', fontWeight:'700'}}> A Small Mirror  to Real Bharat</h2>  */}

            </div>
          </div>
        </div>
      </section>



      <section>
        <div className='container my-2'>
          <div className='row d-flex' style={{ overflowX: "scroll" }}>
            {
              smallMirrors?.map((res) => {
                return (
                  <>
                    <div className='col-lg-2 col-md-6 col-sm-12 mt-3'
                    // onClick={() => { navigate(`/${res.slug}`); setUser(true) }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={res?.topimages[0]?.img_path} alt='image' style={{ width: '70%' }} />
                      </div>
                      <h5 className='text-center mt-3' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>{res?.name}</h5>
                    </div>
                  </>
                )
              })
            }

          </div>
        </div>
      </section>


      <section>
        <div className='container my-2'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid orange", height: '0px' }}> </div>
                <div className='col-sm-12 col-md-6 pooja' style={{ justifyContent: "center", alignItems: "center" }}>
                  <h2 className='Rahul' style={{
                    whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                    overflow: "hidden",   /* Hides any content that overflows the container */
                    textOverflow: "ellipsis", textShadow: '1.1px 0px 0px #c1c1c1',
                    textTransform: "capitalize"
                  }}>{trendingCategory?.name}</h2>
                </div>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid green", height: '0px' }}> </div>
              </div>

              {/* <h2 style={{textAlign:'center', fontWeight:'700'}}> Top Treding Destinations India 2024 </h2>  */}

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container my-5'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>

              <div onClick={() => { navigate(`/${trendingCategory?.posts[Count1]?.post_slug}`); setUser(true) }} className='mt-2' style={divStyle1}>
                <h2 className='pb-4' style={{ paddingTop: '70%',  borderRadius: "8px", textAlign: 'center', color: '#fff', textShadow: '1.1px 0px 0px #c1c1c1', cursor: "pointer", backgroundColor: 'rgba(0, 0, 0, 0.4)' }} onMouseEnter={(e) => {
                  e.currentTarget.style.color = 'yellow';  // Change to desired hover color

                }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'white';

                  }}>{trendingCategory?.posts[Count1]?.post_name}</h2>
              </div>


            </div>

            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div className='row'>
                {
                  trendingCategory?.posts?.map((res, index) => {
                    return (
                      <div key={index} className="col-4">
                        <button
                          onClick={() => {
                            navigate(`/${res.post_slug}`);
                            setUser(true);
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "15px 15px",
                            marginTop: 20,
                            marginBottom: 20,
                            cursor: "pointer",
                            border: "1px solid #ccc",
                            borderRadius: 10,
                            backgroundColor: "rgb(233 233 233 / 54%)",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            textAlign: "left",
                            width: "100%",  // Ensure the button takes full width of the column
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.15)';
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                          }}
                        >
                          <h6
                            style={{
                              fontSize: "16px",
                              margin: 0,
                              whiteSpace: "normal", // Allows text to wrap into two lines if needed
                              overflow: "hidden",   // Ensures no overflow outside the button
                              textOverflow: "ellipsis", // Add ellipsis for any extra text
                              display: "-webkit-box",  // Used for limiting lines of text
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,  // Limits to two lines before ellipsis
                              lineHeight: "1.2em",  // Line height for better readability
                              maxHeight: "2.4em", // Limit the button height to fit two lines
                            }}
                          >
                            {res?.post_name}
                          </h6>
                        </button>
                      </div>
                    );
                  })
                }
              </div>
            </div>

          </div>
        </div>
      </section>



      <section>
        <div className='container my-5'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid orange", height: '0px' }}> </div>
                <div className='col-sm-12 col-md-6 pooja' style={{ justifyContent: "center", alignItems: "center" }}>
                  <h2 className='Rahul' style={{
                    whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                    overflow: "hidden",   /* Hides any content that overflows the container */
                    textOverflow: "ellipsis"
                  }}>Comprehensive Queries</h2>
                </div>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid green", height: '0px' }}> </div>
              </div>

              {/* <h2 style={{textAlign:'center', fontWeight:'700'}}> Frequntly Asked Question</h2>  */}

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container my-5'>
          <div className='row'>
            <div className='col-lg-7 col-md-7 col-sm-12'>
              <div class="accordion" id="accordionExample">

                {/* {
                      Data?.homequestion_keys?.map((res,index)=>{
                        return(
                          <>
                            <div class="accordion-item  border-0" style={{ borderRadius: '8px', backgroundColor: 'transparent' }}>
                            <h2 class="accordion-header" id={`headingTwo${index}`}>
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseTwo${index}`} aria-expanded="false" aria-controls={`collapseTwo${index}`} style={{ fontSize: '26px', fontWeight: '600', borderRadius: '8px', boxShadow: 'none', backgroundColor: 'transparent', textDecoration: 'underline', textShadow: '1.1px 0px 0px #c1c1c1' }}>
                            
                              </button>
                            </h2>
                            <div id={`collapseTwo${index}`} class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{ backgroundColor: 'transparent' }}>
                              <div class="accordion-body" style={{ backgroundColor: 'transparent', textShadow: '1.1px 0px 0px #c1c1c1', textDecoration: 'underline' }}>
                                <h6 style={{ fontSize: '18px' }}> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, non. Eaque earum nulla culpa iusto doloribus consequatur optio minima consectetur debitis dolore?</h6>
                              </div>
                            </div>
                          </div>
                          </>
                        )
                      })
                     } */}
                {
                  Data?.homequestion_keys?.map((res, index) => {
                    return (
                      <div className="accordion-item border-2 my-2 " style={{ borderRadius: '8px', backgroundColor: 'transparent', cursor: "pointer" }}>
                        <div className='d-flex'>
                          <h2 data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} style={{ marginLeft: 15, marginTop: 5 }}>+</h2>
                          <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}
                              style={{ fontSize: '18px', fontWeight: '600', borderRadius: '8px', backgroundColor: 'transparent', boxShadow: 'none' }}>
                              {res?.name}
                            </button>
                          </h2>
                        </div>
                        <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          {/* <div className="accordion-body">
                            <h6 style={{ fontSize: '18px' }}>{res?.value}</h6>
                          </div> */}
                          <div className="accordion-body" dangerouslySetInnerHTML={{ __html: res?.value }} />
                        </div>
                      </div>
                    )
                  }
                  )
                }


                {/* <div class="accordion-item  border-0" style={{ borderRadius: '8px', backgroundColor: 'transparent' }}>
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ fontSize: '26px', fontWeight: '600', borderRadius: '8px', boxShadow: 'none', backgroundColor: 'transparent', textDecoration: 'underline', textShadow: '1.1px 0px 0px #c1c1c1' }}>
                      Lorem ipsum dolor sit amet consectetur
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{ backgroundColor: 'transparent' }}>
                    <div class="accordion-body" style={{ backgroundColor: 'transparent', textShadow: '1.1px 0px 0px #c1c1c1', textDecoration: 'underline' }}>
                      <h6 style={{ fontSize: '18px' }}> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, non. Eaque earum nulla culpa iusto doloribus consequatur optio minima consectetur debitis dolore?</h6>
                    </div>
                  </div>
                </div>

                <div class="accordion-item   border-0" style={{ borderRadius: '8px', backgroundColor: 'transparent' }}>
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
                      style={{ fontSize: '26px', fontWeight: '600', borderRadius: '8px', backgroundColor: 'transparent', boxShadow: 'none', textDecoration: 'underline' }}>
                      Lorem ipsum dolor sit amet consectetur
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <h6 style={{ fontSize: '18px' }}> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, non. Eaque earum nulla culpa iusto doloribus consequatur optio minima consectetur debitis dolore?</h6>
                    </div>
                  </div>
                </div>



                <div class="accordion-item   border-0" style={{ borderRadius: '8px', backgroundColor: 'transparent' }}>
                  <h2 class="accordion-header" id="headingThree4">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree4" aria-expanded="false" aria-controls="collapseThree4"
                      style={{ fontSize: '26px', fontWeight: '600', borderRadius: '8px', boxShadow: 'none', backgroundColor: 'transparent', textDecoration: 'underline' }}>
                      Lorem ipsum dolor sit amet consectetur,
                    </button>
                  </h2>
                  <div id="collapseThree4" class="accordion-collapse collapse" aria-labelledby="headingThree4" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <h6 style={{ fontSize: '18px' }}> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, non. Eaque earum nulla culpa iusto doloribus consequatur optio minima consectetur debitis dolore?</h6>
                    </div>
                  </div>
                </div>

                <div class="accordion-item   border-0" style={{ borderRadius: '8px', backgroundColor: 'transparent' }}>
                  <h2 class="accordion-header" id="headingThree5">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree5" aria-expanded="false" aria-controls="collapseThree5"
                      style={{ fontSize: '26px', fontWeight: '600', borderRadius: '8px', textDecoration: 'underline', backgroundColor: 'transparent', boxShadow: 'none' }}>
                      Lorem ipsum dolor sit amet consectetur
                    </button>
                  </h2>
                  <div id="collapseThree5" class="accordion-collapse collapse" aria-labelledby="headingThree5" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <h6 style={{ fontSize: '18px' }}> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, non. Eaque earum nulla culpa iusto doloribus consequatur optio minima consectetur debitis dolore?</h6>
                    </div>
                  </div>
                </div>

                <div class="accordion-item   border-0" style={{ borderRadius: '8px', backgroundColor: 'transparent' }}>
                  <h2 class="accordion-header" id="headingThree6">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree6" aria-expanded="false" aria-controls="collapseThree6"
                      style={{ fontSize: '26px', fontWeight: '600', borderRadius: '8px', boxShadow: 'none', backgroundColor: 'transparent', textDecoration: 'underline' }}>
                      Lorem ipsum dolor sit amet consectetur
                    </button>
                  </h2>
                  <div id="collapseThree6" class="accordion-collapse collapse" aria-labelledby="headingThree6" data-bs-parent="#accordionExample">
                    <div class="accordion-body"  >
                      <h6 style={{ fontSize: '18px' }}> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, non. Eaque earum nulla culpa iusto doloribus consequatur optio minima consectetur debitis dolore?</h6>
                    </div>
                  </div>
                </div> */}
                {/* 
                <div>
                  <p style={{
                    fontSize: '20px', color: '#646464', fontWeight: '400',
                    textShadow: '1.1px 0px 0px #c1c1c1', textDecoration: 'underline'
                  }}>More questions...</p>
                </div> */}
              </div>
            </div>
            <div className='col-lg-5 col-md-5 col-sm-12'>
              {/* <img src={Right} alt='image' style={{ width: '100%' }} /> */}
              <div onClick={() => { navigate(`/${RightTextSlug}`); setUser(true) }} className='' style={divStyle2}>
                <h2 className='pb-4' style={{ paddingTop: '70%',   borderRadius: "8px",textAlign: 'center', color: '#fff', textShadow: '1.1px 0px 0px #c1c1c1', cursor: "pointer", backgroundColor: 'rgba(0, 0, 0, 0.4)' }} onMouseEnter={(e) => {
                  e.currentTarget.style.color = 'yellow';  // Change to desired hover color

                }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'white';

                  }}>{RightText}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className='container my-5'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'>
              <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid orange", height: '0px' }}> </div>
                <div className='col-sm-12 col-md-6 pooja' style={{ justifyContent: "center", alignItems: "center" }}>
                  <h2 className='Rahul' style={{
                    whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                    overflow: "hidden",   /* Hides any content that overflows the container */
                    textOverflow: "ellipsis"
                  }}>Do Follow Us to Stay Updated</h2>
                </div>
                <div className='col-sm-0 col-md-3 mt-3' style={{ justifyContent: "center", alignItems: "center", border: "3px solid green", height: '0px' }}> </div>
              </div>

              {/* <h2 style={{textAlign:'center', fontWeight:'700'}}> Frequntly Asked Question</h2>  */}

            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='container my-5'>
          <div className='row'>

            <div className='col-lg-5 col-md-6 col-sm-12'>
              <div onClick={() => { navigate(`/${leftTextSlug}`); setUser(true) }} className='' style={divStyle3}>
                <h2 className='pb-4' style={{ paddingTop: '70%',  borderRadius: "8px", textAlign: 'center', color: '#fff', textShadow: '1.1px 0px 0px #c1c1c1', cursor: "pointer", backgroundColor: 'rgba(0, 0, 0, 0.4)' }} onMouseEnter={(e) => {
                  e.currentTarget.style.color = 'yellow';  // Change to desired hover color

                }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = 'white';

                  }}>{leftText}</h2>
              </div>
              {/* <img src={left} alt='image' style={{ width: '100%' }} /> */}

            </div>
            <div className='col-lg-7 col-md-6 col-sm-12'>
              {/* <a href="https://www.youtube.com/channel/UC9nYP8zZXGFlEmA2gIS1SVA" target="_blank" rel="noopener noreferrer">
                <img
                  src={Data[0]}
                  alt="YouTube Channel Thumbnail"
                  style={{ width: '100%', maxWidth: '400px', height: 'auto' }}
                />
              </a> */}
              <iframe style={{ width: "100%", height: "100%", borderRadius: 10 }} src="https://www.youtube.com/embed/gWunLeh7hOk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

          </div>
        </div>
      </section>

      <Footer />

    </>
  )
}

export default Home