import React,{useState,useEffect, useRef, useContext} from 'react'
// var React = require('react'); 
// var Carousel = require('react-responsive-carousel').Carousel;
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { toast, ToastContainer } from 'react-toastify';
import Header from './Header';
import './Header.css'
import { useNavigate, useParams } from 'react-router-dom';
import right from "../Component/Image/right.png";
import left from "../Component/Image/left.png"
import Context from '../Context/Context';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer';
import { FaArrowDown } from 'react-icons/fa6';


const TripPlanner = () => {


    const { id } = useParams();
    const navigate = useNavigate();


    const [Data, setData] = useState();
    const [HeaderImages, setHeaderImages] = useState([])
    const [Top, setTop] = useState();
    const [Top_first, setTop_first] = useState();
    const [top_second, setTop_second] = useState();
    const [Gallery, setGallery] = useState([]);
    const [moreData, setMoreData] = useState()
    const galleryContainerRef = useRef(null);
    const galleryContainerRef1 = useRef(null);
    const galleryContainerRef2 = useRef(null);
    const [colWidth, setColWidth] = useState(0);
    const [numColumns, setNumColumns] = useState(4);
    const [colWidth1, setColWidth1] = useState(0);
    const [numColumns1, setNumColumns1] = useState(4);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [loader, setLoader] = useState(true);
    const [ShortImage, setShortImage] = useState();
    const [slected, setslected] = useState(0);
    const [carausl, setCarausl] = useState([]);
    const { user, setUser } = useContext(Context);
    const [showFullContent, setShowFullContent] = useState(false);
    const [numberOfTravelers, setNumberOfTravelers] = useState('');
    const [timeInHand, setTimeInHand] = useState('');
    const [tripType, setTripType] = useState('');
    const [tripLocation, setTripLocation] = useState('');
    const [overallBudget, setOverallBudget] = useState('');
    const [travelMedium, setTravelMedium] = useState('');
    const [mobileNumbers, setMobileNumbers] = useState('');

    const handleNumberOfTravelersChange = (event) => {
        setNumberOfTravelers(event.target.value);
    };

    const handleTimeInHandChange = (event) => {
        setTimeInHand(event.target.value);
    };

    const handleTripTypeChange = (event) => {
        setTripType(event.target.value);
    };

    const handleTripLocationChange = (event) => {
        setTripLocation(event.target.value);
    };

    const handleOverallBudgetChange = (event) => {
        setOverallBudget(event.target.value);
    };

    const handleTravelMediumChange = (event) => {
        setTravelMedium(event.target.value);
    };

    const handleMobileNumbersChange = (event) => {
        setMobileNumbers(event.target.value);
    };

    //   const handleFormSubmit = () => {
    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    //     const urlencoded = new URLSearchParams();
    //     urlencoded.append("travellers", numberOfTravelers);
    //     urlencoded.append("time_hand", timeInHand);
    //     urlencoded.append("trip_type", tripType);
    //     urlencoded.append("trip_location", tripLocation);
    //     urlencoded.append("budget", overallBudget);
    //     urlencoded.append("travel_medium", travelMedium);
    //     urlencoded.append("mobile_no", mobileNumbers);

    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: urlencoded,
    //         redirect: "follow"
    //     };

    //     fetch("https://api.placestovisitindia.com/api/inquiries", requestOptions)
    //         .then((response) => response.json())
    //         .then((result) => {
    //             console.log(result);
    //             clearForm(); 
    //             toast.success("Form submitted successfully!");// Log the API response
    //             // Handle any further actions based on API response
    //         })
    //         .catch((error) => console.error(error));
    // };

    const handleFormSubmit = () => {
      // Check if any field is empty
      if (
        !numberOfTravelers ||
        !timeInHand ||
        !tripType ||
        !tripLocation ||
        !overallBudget ||
        !travelMedium ||
        !mobileNumbers
      ) {
        toast.error("All fields are required!");
        return;
      }
  
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
      const urlencoded = new URLSearchParams();
      urlencoded.append("travellers", numberOfTravelers);
      urlencoded.append("time_hand", timeInHand);
      urlencoded.append("trip_type", tripType);
      urlencoded.append("trip_location", tripLocation);
      urlencoded.append("budget", overallBudget);
      urlencoded.append("travel_medium", travelMedium);
      urlencoded.append("mobile_no", mobileNumbers);
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };
  
      fetch("https://api.placestovisitindia.com/api/inquiries", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          clearForm();
          toast.success("Form submitted successfully!");
        })
        .catch((error) => console.error(error));
    };

  
    const toggleContent = () => {
      setShowFullContent(!showFullContent);
    };
  
    const [showFullContent1, setShowFullContent1] = useState(false);
  
    const toggleContent1 = () => {
      setShowFullContent1(!showFullContent);
    };
  
    const handleNext = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex === Gallery.length - 1 ? 0 : prevIndex + 1));
    };
  
    const handlePrevious = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? Gallery.length - 1 : prevIndex - 1));
    };
  

    useEffect(() => {
      if (galleryContainerRef.current) {
        const col = galleryContainerRef.current.querySelector('.col-lg-3');
        if (col) {
          const width = col.offsetWidth;
          setColWidth(width);
        }
      }
      // Calculate the number of columns for mobile
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 768) {
          setNumColumns(1);
        } else {
          setNumColumns(4);
        }
      };
      handleResize(); // Call once to set initial number of columns
      window.addEventListener('resize', handleResize); // Add event listener for window resize
      return () => window.removeEventListener('resize', handleResize); // Remove event listener on component unmount
    }, [Gallery]);
  
  
    useEffect(() => {
      // Calculate the width of a single col-lg-3 element
      if (galleryContainerRef1.current) {
        const col = galleryContainerRef1.current.querySelector('.col-lg-3');
        if (col) {
          const width = col.offsetWidth;
          setColWidth1(width);
        }
      }
      // Calculate the number of columns for mobile
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 768) {
          setNumColumns1(1);
        } else {
          setNumColumns1(4);
        }
      };
      handleResize(); // Call once to set initial number of columns
      window.addEventListener('resize', handleResize); // Add event listener for window resize
      return () => window.removeEventListener('resize', handleResize); // Remove event listener on component unmount
    }, [moreData]);
  
    const handleScroll = (scrollOffset) => {
        const container = galleryContainerRef.current;
        if (container) {
          container.scrollLeft += scrollOffset;
        }
      };
  
    const handleScroll1 = (scrollOffset) => {
        const container = galleryContainerRef1.current;
        if (container) {
          container.scrollLeft += scrollOffset;
        }
     
    };

    const handleScroll2 = (scrollOffset) => {
        const container = galleryContainerRef2.current;
        if (container) {
          container.scrollLeft += scrollOffset;
        }
     
    };
  
  
  
  
    const Get_Api = () => {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
  
      fetch(`https://api.placestovisitindia.com/api/getBaseCategories/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setData(result.data);
          let data = (result?.data?.images || [])
          .filter(image => image.image_type === "header") // Filter images where image_type is "header"
          .map(headerImage => headerImage.img_path); // Map to extract img_path of header images
        
        console.log(data, "fdgfdfgfg====>");
        
        setCarausl(data); // Assuming setCarausl is a function to set carousel data
        
        
        // setCarausl(data); 
          window.scrollTo(0, 0);
          setLoader(false)
        })
        .catch(error => console.log('error', error));
    }
  
    useEffect(() => {
      Get_Api();
    }, [user])
  
    
    const renderCustomIndicator = (clickHandler, isSelected, index, label) => {

      console.log(isSelected,"========>item");

      const indicatorStyle = {
        width: '20px',
        height: '20px',
        margin: '0 5px',
        background: isSelected ? '#fff' : '#888', // Change the background color based on selection
        borderRadius: '10%',
        cursor: 'pointer',
        display: 'inline-block',
      };
    
      return (
        <li
          key={index}
          style={indicatorStyle}
          onClick={clickHandler}
          role="button"
          tabIndex={0}
          title={`${label} ${index + 1}`}
        />
      );
    };
    
  
  
  
    function truncateText(text, maxLength) {
      if (text.length <= maxLength) return text;
      return text.substr(0, maxLength) + '...';
    }
  
    function truncateText1(text, maxLength) {
      if (text.length <= maxLength) return text;
      return text.substr(0, maxLength) + '...';
    }
  
    const clearForm = () => {
      setNumberOfTravelers('');
      setTimeInHand('');
      setTripType('');
      setTripLocation('');
      setOverallBudget('');
      setTravelMedium('');
      setMobileNumbers('');
  };

  return (
    <div>
        <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar={false} />
        <Header/>
        <section>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 col-sm-12'>

              <Carousel autoPlay infiniteLoop interval={3000} showThumbs={false} showIndicators={true} renderIndicator={renderCustomIndicator}>
                {
                 carausl?.map((res) => {
                    return (
                      <>
                      {
                   
                         <div>
                          <img src={res} alt='img' style={{ height: "100%" }} />
                          <h1 style={{ backgroundColor: 'transparent', fontSize: '28px',}} className="legend">{Data?.name} <br /></h1>
                        </div>
                        
                      }
                       
                      </>
                    )
                  })
                }

              </Carousel>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className='container mt-3'>
            <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid orange", height:'0px'}}> </div>
                      <div className='col-sm-12 col-md-6 pooja' style={{justifyContent:"center",alignItems:"center"}}>
                        <h2 className='Rahul' style={{
                            //  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                             overflow: "hidden",   /* Hides any content that overflows the container */
                             textOverflow: "ellipsis",textShadow: '1.1px 0px 0px #c1c1c1'   
                        }}>{Data?.upr_sec_title}</h2>
                      </div>
                      <div className='col-sm-0 col-md-3 mt-3 new' style={{justifyContent:"center",alignItems:"center",border:"3px solid green", height:'0px'}}> </div>
                    </div>
                <div className='row'>

                    <div className='col-lg-12 col-sm-12'>
                       

                       
                    
                    
                       <div dangerouslySetInnerHTML={{__html:Data?.upr_sec_desc}}/>
                       
                     
                    </div>
                </div>
            </div>
        </section>

        
        
        <section>
            <div className='container my-5'>
            <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid orange", height:'0px'}}> </div>
                      <div className='col-sm-12 col-md-6 pooja' style={{justifyContent:"center",alignItems:"center"}}>
                        <h2 className='Rahul' style={{
                            //  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                             overflow: "hidden",   /* Hides any content that overflows the container */
                             textOverflow: "ellipsis",textShadow: '1.1px 0px 0px #c1c1c1',textTransform:"capitalize"
                        }}>{Data?.most_popular_category?.name}</h2>
                      </div>
                      <div className='col-sm-0 col-md-3 mt-3 new' style={{justifyContent:"center",alignItems:"center",border:"3px solid green", height:'0px'}}> </div>
                    </div>
                <div className='row'>

                    <div className='col-lg-12 m-auto'>
                        <div className='row'>

                            {
                            Data?.most_popular_category?.posts?.map((res,index)=>{
                              console.log(res,"res==============>")
                               return(
                                <>
                                {/* onClick={()=>{navigate(`/${}/${}`)}} */}
                                <div className='col-lg-4 col-md-6 col-sm-12' onClick={()=>{if(res.status===1){navigate(`/${Data?.slug}/${res?.post_slug}`);setUser(true)}}}>
                       
                       <div className='card mt-3' style={{borderRadius:10,overflow:"hidden",cursor:"pointer",}}>
                        <div className='card-header p-0'>
                         <img src={res?.images[0]?.img_path} alt='img' style={{width:'100%',height:"300px"}}/>
                         <h4  className='text-center py-2' style={{textShadow: '1.1px 0px 0px #c1c1c1'}}>{res?.post_title}</h4>
                        </div>
                        <div className='card-body '>
                            

                            <div className='ps-1'>
                                {
                                    res?.short_keys?.map((res,index)=>{
                                        return(
                                            <>
                                            {
                                                index>2?null:<> <h5 style={{textShadow: '1.1px 0px 0px #c1c1c1'}}>{res.name}:   {res.value}</h5></>
                                            }
                                              
                                            </>
                                        )
                                    })
                                }
                             
                                {/* <h5 className='py-2' style={{textShadow: '1.1px 0px 0px #c1c1c1'}}>Difficulty  -  Easy</h5>
                                <h5 style={{textShadow: '1.1px 0px 0px #c1c1c1'}}>Best Time  -   Dec to March</h5> */}
                            </div>
                        </div>

                       </div>
                    
                       <div>
                        
                       </div>
                    </div>
                                </>
                               )
                            })
                            }
            
                    
                    </div>
                    </div>
                </div>


               
            </div>
        </section>



        <section>
            <div className='container my-4'>
            <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid orange", height:'0px'}}> </div>
                      <div className='col-sm-12 col-md-6 pooja' style={{justifyContent:"center",alignItems:"center"}}>
                        <h2 className='Rahul' style={{
                            //  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                             overflow: "hidden",   /* Hides any content that overflows the container */
                             textOverflow: "ellipsis",textShadow: '1.1px 0px 0px #c1c1c1'
                        }}>{Data?.middle_section_category?.title}
                        </h2>
                      </div>
                      <div className='col-sm-0 col-md-3 mt-3 new' style={{justifyContent:"center",alignItems:"center",border:"3px solid green", height:'0px'}}> </div>
                    </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12' dangerouslySetInnerHTML={{__html:Data?.middle_section_category?.description}}>
                    
                    </div>
                </div>
            </div>
        </section>


        <section>
            <div className='container'>
                <div className='mb-4 mx-2' style={{flexDirection:"row",display:"flex",overflowX:"scroll",justifyContent:"space-around"}}>
                {
                  Data?.middle_section_category?.children?.map((res,index)=>{
                    return(
                        <>
                         <div className='mx-2' style={{alignItems:"center",width:200}}>
                        <button onClick={()=>{
                            setslected(index)
                        }} className='p-2 mx-2' style={{width:200,border:0,borderRadius:10,backgroundColor:slected==index ? "gray" :""}}>
                        <h5>
                        {
                            res?.title
                        }
                        </h5>
                        </button>
                        </div>
                        </>
                    )
                  })  
                 }
                </div>
            <div className='row'>
            <h2 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>{Data?.photo_galary_title}</h2>
            <div className={` col-lg-12 col-md-12 col-sm-12 kkk ${numColumns === 1 ? 'flex-wrap' : ''}`} style={{ overflowX: "scroll" }} ref={galleryContainerRef}>
                {
                [Data?.middle_section_category?.children[slected]]?.map((res, index) => (
                    res?.posts?.map((post) => (
                    <div key={index} className='col-lg-3 col-md-6 col-sm-12' onClick={()=>{if(post?.status===1){navigate(`/${Data?.slug}/${post?.post_slug}`);setUser(true)}}}>
                        <div className=' mx-3' style={{cursor:"pointer",}}>
                        <div className='card-body p-0'>
                        {/* {console.log("post========>11",post)} */}
                            {
                            post?.images?.map((image, imageIndex) => (
                                image?.image_type === "top" &&
                                <img key={imageIndex} src={image.img_path} alt='image' style={{ width: '100%', height: 270,borderRadius:10,overflow:"hidden", }} />
                            ))
                            }
                        </div>
                        <h5 className='my-2 text-center' style={{ textShadow: '1.1px 0px 0px #c1c1c1' }}>{post.post_title}</h5>
                        </div>
                        
                    </div>
                    ))
                ))
                }
            </div>
            </div>
    <div className="mt-3 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
           <img src={left} onClick={()=>{handleScroll(-450)}} style={{ cursor: "pointer", marginRight: 20 }} />
      <img src={right} onClick={()=>{handleScroll(450)}} style={{ cursor: "pointer", marginRight: 0 }} />
     
    </div>

            </div>
        </section>

        <section>
            <div className='container my-4'>

            <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid orange", height:'0px'}}> </div>
                      <div className='col-sm-12 col-md-6 pooja' style={{justifyContent:"center",alignItems:"center"}}>
                        <h2 className='Rahul' style={{
                            //  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                             overflow: "hidden",   /* Hides any content that overflows the container */
                             textOverflow: "ellipsis",textShadow: '1.1px 0px 0px #c1c1c1'
                        }}>{Data?.lower_section_category?.title}</h2>
                      </div>
                      <div className='col-sm-0 col-md-3 mt-3 new' style={{justifyContent:"center",alignItems:"center",border:"3px solid green", height:'0px'}}> </div>
                    </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12' dangerouslySetInnerHTML={{__html:Data?.lower_section_category?.description}}>
                    </div>
                </div>
            </div>
        </section>

        

        <section className='mt-4'>
  <div className='container'>
    <div className='row'>
      <h2 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>{Data?.photo_galary_title}</h2>
      <div className={`col-lg-12 col-md-12 col-sm-12 kkk ${numColumns === 1 ? 'flex-wrap' : ''}`} style={{ overflowX: "scroll" }} ref={galleryContainerRef1}>
        {Data?.lower_section_category?.posts?.map((res, index) => (
          <div key={index} className='col-lg-3 col-md-6 col-sm-12' onClick={()=>{if(res.status===1){navigate(`/${Data?.slug}/${res?.post_slug}`);setUser(true)}}}>
            <div className=' mx-3' style={{cursor:"pointer"}}>
              <div className='card-body p-0'>
                {res?.images?.map((image, imageIndex) => (
                  image?.image_type === "top" &&
                  <img key={imageIndex} src={image.img_path} alt='image' style={{ width: '100%', height: 270,borderRadius:10,overflow:"hidden", }} />
                ))}
              </div>
            <h5 className='my-2 text-center' style={{ textShadow: '1.1px 0px 0px #c1c1c1' }}>{res.post_title}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
    {/* Buttons for scrolling */}
    <div className="mt-3 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
      <img src={left} onClick={() => handleScroll1(-450)} style={{ cursor: "pointer", marginRight: 20 }} />
      <img src={right} onClick={() => handleScroll1(450)} style={{ cursor: "pointer", marginRight: 0 }} />
    </div>
  </div>
</section>




        
      


        
        <section>
            <div className='container my-4'>

            <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid orange", height:'0px'}}> </div>
                      <div className='col-sm-12 col-md-6 pooja' style={{justifyContent:"center",alignItems:"center"}}>
                        <h2 className='Rahul' style={{
                            //  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                             overflow: "hidden",   /* Hides any content that overflows the container */
                             textOverflow: "ellipsis",textShadow: '1.1px 0px 0px #c1c1c1'
                        }}>{Data?.additional_lower_section_category?.title}
                        </h2>
                      </div>
                      <div className='col-sm-0 col-md-3 mt-3 new' style={{justifyContent:"center",alignItems:"center",border:"3px solid green", height:'0px'}}> </div>
                    </div>



                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12' dangerouslySetInnerHTML={{__html:Data?.additional_lower_section_category?.description}}>
                       
                    </div>
                </div>
            </div>
        </section>




        <section className='mt-4'>
  <div className='container'>
    <div className='row'>
      <h2 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>{Data?.photo_galary_title}</h2>
      <div className={`col-lg-12 col-md-12 col-sm-12 kkk ${numColumns === 1 ? 'flex-wrap' : ''}`} style={{ overflowX: "scroll" }} ref={galleryContainerRef2}>
        {Data?.additional_lower_section_category?.posts?.map((res, index) => (
          <div key={index} className='col-lg-3 col-md-6 col-sm-12' onClick={()=>{if(res.status===1){navigate(`/${Data?.slug}/${res?.post_slug}`);setUser(true)}}}>
            <div className=' mx-3' style={{cursor:"pointer"}}>
              <div className='card-body p-0'>
                {res?.images?.map((image, imageIndex) => (
                  image?.image_type === "top" &&
                  <img key={imageIndex} src={image.img_path} alt='image' style={{ width: '100%', height: 270,borderRadius:10,overflow:"hidden", }} />
                ))}
              </div>
            <h5 className='my-2 text-center' style={{ textShadow: '1.1px 0px 0px #c1c1c1' }}>{res.post_title}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
    {/* Buttons for scrolling */}
    <div className="mt-3 d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
      <img src={left} onClick={() => handleScroll2(-450)} style={{ cursor: "pointer", marginRight: 20 }} />
      <img src={right} onClick={() => handleScroll2(450)} style={{ cursor: "pointer", marginRight: 0 }} />
    </div>
  </div>
</section>



        {/* <section>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        
                        <div className='card'>

                            <div className='card-body p-0'>
                          <img src={card} alt='image' style={{width:'100%'}}/>
                            </div>
                        </div>

                        <h4 className='my-2 text-center' style={{textShadow: '1.1px 0px 0px #c1c1c1'}}>Hello Rahul</h4>

                    </div>
                </div>
            </div>
        </section> */}




        <section>
            <div className='container my-4'>

            <div className='row'>
                <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid orange", height:'0px'}}> </div>
                      <div className='col-sm-12 col-md-6 pooja' style={{justifyContent:"center",alignItems:"center"}}>
                        <h2 className='Rahul' style={{
                            //  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                             overflow: "hidden",   /* Hides any content that overflows the container */
                             textOverflow: "ellipsis",textShadow: '1.1px 0px 0px #c1c1c1'
                        }}>{Data?.low_sec_title}
                        </h2>
                      </div>
                      <div className='col-sm-0 col-md-3 mt-3 new' style={{justifyContent:"center",alignItems:"center",border:"3px solid green", height:'0px'}}> </div>
                    </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12' dangerouslySetInnerHTML={{__html:Data?.low_sec_desc}}>
                        {/* <h1 className='text-center' style={{textShadow: '1.1px 0px 0px #c1c1c1'}}> </h1>
                        <p style={{ fontWeight:'500',fontSize:'22px' , color: '#646464',
    textShadow: '1.1px 0px 0px #c1c1c1'}}>The Chadar Trek or The Frozen River Expedition is one of the most unique and challenging treks in India. The 6 day chadar trek across the frozen Zanskar River in Ladakh takes place during the latter part of January till end of February or sometimes even in the first week of March, depending on the weather.
                            
                        Brahmatal Trek, Chadar Trek, Dayara Bugyal Trek, Kedarkantha Trek, Deoriatal Chandrashila Trek, Everest Base Camp Trek, Har Ki Dun Trek, Sandakphu Trek, Valley of Flowers Trek, Tarsar Marsar Trek and many more adventurous Himalayan treks.
                        </p> */}
                    </div>
                </div>
            </div>
        </section>


        <section className='mt-4'>
          
        <div className='container ' style={{  }}>
          <div className='row'>
          <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid orange", height:'0px'}}> </div>
                      <div className='col-sm-12 col-md-6 pooja' style={{justifyContent:"center",alignItems:"center"}}>
                        <h2 className='Rahul' style={{
                            //  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                             overflow: "hidden",   /* Hides any content that overflows the container */
                             textOverflow: "ellipsis",textShadow: '1.1px 0px 0px #c1c1c1'
                        }}>Frequently Asked Questions
                        </h2>
                      </div>
                      <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid green", height:'0px'}}> </div>
          </div>
          <div className='row'>
      
            {/* <h2 style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1' }}>Faq Asked Questions</h2> */}
            {/* <ComingSoon/> */}
            <div className='col-lg-12 col-md-12 col-sm-12 mt-4'>

              <div style={{ lineHeight: '32px' }}>
                <div className="accordion" id="accordionExample">

                  {
                    Data?.basecategoryquestion?.map((res, index) => {
                      return (
                        <div className="accordion-item border-2 my-2 " style={{ borderRadius: '8px', backgroundColor: 'transparent',cursor:"pointer" }}>
                          <div className='d-flex'>
                          {/* <h2 data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} style={{marginLeft:15,marginTop:5}}><FaArrowDown</h2> */}
                          <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}
                              style={{ fontSize: '18px', fontWeight: '600', borderRadius: '8px', backgroundColor: 'transparent', boxShadow: 'none' }}>
                              {res?.name}
                            </button>
                          </h2>
                          </div>
                          <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <h6 style={{ fontSize: '18px' }}>{res?.value}</h6>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    )
                  }


                </div>
              </div>

            </div>
          </div>
        </div>
      </section>




        <section>
            <div className='container my-4'> 
            <div className='row'>
            <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid orange", height:'0px'}}> </div>
                      <div className='col-sm-12 col-md-6 pooja' style={{justifyContent:"center",alignItems:"center"}}>
                        <h2 className='Rahul' style={{
                            //  whiteSpace: "nowrap",/* Prevents text from wrapping to the next line */
                             overflow: "hidden",   /* Hides any content that overflows the container */
                             textOverflow: "ellipsis",textShadow: '1.1px 0px 0px #c1c1c1'
                        }}>                    <h2 className='' style={{fontWeight:'700',textShadow: '1.1px 0px 0px #c1c1c1',marginTop:-1,textTransform:"capitalize"}}> {Data?.cta_title}</h2>

                        </h2>
                      </div>
                      <div className='col-sm-0 col-md-3 mt-3' style={{justifyContent:"center",alignItems:"center",border:"3px solid green", height:'0px'}}> </div>
                      </div>
                <div className='row'>
                    <div className='col-lg-7 col-md-6 col-sm-12 mt-4'>
                    
                    {/* <h2 className='  mt-5' style={{fontWeight:'700',textShadow: '1.1px 0px 0px #c1c1c1'}}> {Data?.cta_title}</h2> */}
                    <div dangerouslySetInnerHTML={{__html:Data?.cta_description}}/>
                  </div>

                    <div className='col-lg-5 col-md-6 col-sm-12 mt-2 p-4 jkl'>

                        <div className='card' style={{backgroundColor:'#e9e9e98a',borderWidth:1,borderColor:"#303091"}}>
                            {/* <div className='card-body'>
                                

                            <div class="mb-3">
                         
                         <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Number Of Travelers'
                          style={{fontSize:'20px',textShadow: '1.1px 0px 0px #c1c1c1'}}/> 
                     </div>
 
 
                     <div class="mb-3"> 
                         <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Time in Hand (Days & Nights)'  style={{fontSize:'20px',textShadow: '1.1px 0px 0px #c1c1c1'}}/> 
                     </div>
 
                     
                     <div class="mb-3"> 
                         <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Trip Type (Trek/Adventure/etc)'  style={{fontSize:'20px',textShadow: '1.1px 0px 0px #c1c1c1'}}/> 
                     </div>
                     
                     <div class="mb-3"> 
                         <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Trip Location (Ex- Rishikesh)'  style={{fontSize:'20px',textShadow: '1.1px 0px 0px #c1c1c1'}}/> 
                     </div>
                     
                     <div class="mb-3"> 
                         <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Overall Budget (INR)'  style={{fontSize:'20px',textShadow: '1.1px 0px 0px #c1c1c1'}}/> 
                     </div>
                     
                     <div class="mb-3"> 
                         <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Travel Medium (Air/Rail/Road/All)'  style={{fontSize:'20px',textShadow: '1.1px 0px 0px #c1c1c1'}}/> 
                     </div>

                     <div class="mb-3"> 
                         <input type="email" class="form-control py-3" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Mobile numbers'  style={{fontSize:'20px',textShadow: '1.1px 0px 0px #c1c1c1'}}/> 
                     </div>
 
                     <div style={{display:'flex', justifyContent:'center'}}>
                     <button type="button"  style={{width:'70%', backgroundColor:'#303091', border:'2px solid #5bc1b8', padding:'8px 14px', borderRadius:'8px', fontSize:'22px', color:'#fff'}}>Send Enquiry</button>
                     </div>
                             
                            </div> */}
                              <div className='card-body'>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Number Of Travelers'
                    value={numberOfTravelers}
                    onChange={handleNumberOfTravelersChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Time in Hand (Days & Nights)'
                    value={timeInHand}
                    onChange={handleTimeInHandChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Trip Type (Trek/Adventure/etc)'
                    value={tripType}
                    onChange={handleTripTypeChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Trip Location (Ex- Rishikesh)'
                    value={tripLocation}
                    onChange={handleTripLocationChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Overall Budget (INR)'
                    value={overallBudget}
                    onChange={handleOverallBudgetChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Travel Medium (Air/Rail/Road/All)'
                    value={travelMedium}
                    onChange={handleTravelMediumChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control py-3"
                    placeholder='Mobile numbers'
                    value={mobileNumbers}
                    onChange={handleMobileNumbersChange}
                    style={{ fontSize: '20px', textShadow: '1.1px 0px 0px #c1c1c1' }}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                    type="button"
                    onClick={handleFormSubmit}
                    style={{
                        width: '70%',
                        backgroundColor: '#303091',
                        border: '2px solid #5bc1b8',
                        padding: '8px 14px',
                        borderRadius: '8px',
                        fontSize: '22px',
                        color: '#fff'
                    }}
                >
                    Send Enquiry
                </button>
            </div>
            </div>
                        </div>
                   
                            </div>

                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default TripPlanner