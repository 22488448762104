import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import About from "./Image/copyrightpolicy.jpg"


const Copyrightpolicy = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    const divStyle = {
        backgroundImage: `url(${About})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '500px', // Adjust height as needed
        display: 'flex',
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
        position: 'relative', // Ensure absolute positioned elements are relative to this div
        color: '#fff', // Text color
        marginTop: 40
    };
    return (
        <div>
            <Header />

            <div className='container-fluid mt-5'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
                        <div className='' style={divStyle}>

                        </div>
                    </div>
                </div>
            </div>


            {/* <div className='mt-5'> */}
            <div className='mt-5'>


                <section className='mt-4'>
                    <div className='container px-5 pt-3 hii' style={{ backgroundColor: "#DBECF1" }}>
                        <div className='row'>

                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                {/* <h4 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1', textTransform: "capitalize" }}>
                                Unveiling the Enchanting Mosaic of India: <a className='active'>PlacesToVisitIndia.com</a> 
                            </h4> */}

                                <div>
                                    <p>

                                        <p className='mt-3'>Copyright Policy</p>

                                        The content, including text, images, graphics, and multimedia elements, displayed on the Places to Visit India website (www.placestovisitindia.com) is protected by copyright laws and is the intellectual property of Places to Visit India or its content providers.

                                        <p className='mt-3'>Use of Content</p>

                                        Visitors to the Website are granted a limited, non-exclusive, and revocable license to access and use the content for personal, non-commercial purposes only. This license does not permit the reproduction, distribution, modification, or public display of any content without prior written consent from Places to Visit India.
                                        <p className='mt-3'>Copyright Infringement</p>
                                        Places to Visit India respects the intellectual property rights of others and expects its users to do the same. If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement on the Website, please provide the following information through email at cs@placestovisitindia.com:
                                        <p className='mt-3'>Identification of the copyrighted work claimed to have been infringed.</p>
                                        Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit Places to Visit India to locate the material.
                                        Your contact information, including name, address, telephone number, and email address.
                                        A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.
                                        A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                                        <p className='mt-3'>Your physical or electronic signature.</p>
                                        {/* Any disputes arising from the use of our Website or concerning this Privacy Policy shall be resolved in accordance with the laws of Jaipur, India, and the exclusive jurisdiction of the courts in Jaipur shall apply. */}
                                        <p className='mt-3'>Claiming Copyright</p>
                                        If you wish to claim copyright on any media or content found on the Website, please contact us at cs@placestovisitindia.com.
                                        <p className='mt-3'>Counter-Notification</p>
                                        Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.
                                        A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification.
                                        Your name, address, and telephone number.
                                        A statement that you consent to the jurisdiction of the federal district court for the judicial district in which your address is located, or if your address is outside of the United States, for any judicial district in which Places to Visit India may be found, and that you will accept service of process from the person who provided notification of the alleged infringement.
                                        <br />
                                        Changes to Copyright Policy
                                        <br />
                                        Places to Visit India reserves the right to modify or update this Copyright Policy at any time without prior notice. Such changes will be effective immediately upon posting the updated policy on the Website.

                                    </p>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>



            </div>
            {/* </div> */}
            <div className='mt-3'>

                <Footer />
            </div>
        </div>
    )
}

export default Copyrightpolicy;