import React, { useContext, useEffect, useState } from 'react'
import { CiFacebook, CiLinkedin } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import logo from "../../src/Component/Image/Logo.png"
import { useNavigate } from 'react-router-dom';
import Context from '../Context/Context';
import { FaFacebook } from 'react-icons/fa6';
import x from "./Image/x.jpeg"

const Footer = () => {
  const { user, setUser } = useContext(Context);

  const [data, setData] = useState([]);

  const Navigate = useNavigate();


  useEffect(() => {
    getApi();
  }, [])


  const getApi = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch("https://api.placestovisitindia.com/api/getfooter", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "jjjjjjjj=>");
        setData(result?.data)
      })
      .catch((error) => console.error(error));
  }

  return (
    <div >

      <section className='py-3' style={{ backgroundColor: '#14265B' }}>
        <div className='container  px-3 my-4'>
          <div className='row'>
            <div className='col-lg-3 col-md-6 col-dm-12 mt-3' >
            <div style={{
              //  marginLeft:"60px"
              }}>

                <h5 style={{ fontWeight: '700', textTransform:"capitalize",color:"#fff"}}>places to visit india</h5>

                <ul className='ps-0' style={{ textDecoration: 'none', listStyle: 'none', fontSize: '18px', fontWeight: "500", lineHeight: '38px', color: '#fff', }}>
                  <li style={{ cursor: "pointer",fontSize:"16px" }}>An Encyclopedia on Tourism</li>
                  <li style={{ cursor: "pointer",fontSize:"16px" }}>in India</li>
                </ul>
              </div>

              {/* <div style={{ backgroundColor: "#fff", cursor: "pointer" }} onClick={() => { Navigate(`/`) }}> */}
                {/* <h2>{}</h2> */}
                {/* <img src={logo} style={{ width: "100%", height: "150px", objectFit: "cover" }} /> */}

              {/* </div> */}
              {/* <div className='mt-2 p-2'>
                <h6 style={{color:"#fff"}}>An Encyclopedia on Tourism in India</h6>
              </div> */}



              {/* <div  className="mt-4 mb-3 col-12" style={{display:'inline-flex',justifyContent:"space-between",  }}>
        <a href='https://www.facebook.com/placestovisitIndiaofficial'>
       <FaFacebook  style={{fontSize:'30px'}}/>
       </a>
       <a href='https://www.instagram.com/placestovisitindiaofficial/'>
       <FaInstagram className="mx-2" style={{fontSize:'28px'}}/>
       </a>
       <a href="https://www.youtube.com/@Placestovisitindia-Official">
       <FaYoutube  className="mx-2"style={{fontSize:'28px'}}/>
       </a>
       <a href='https://www.linkedin.com/company/103039021/admin/dashboard'>
       
       <CiLinkedin  className="mx-2"style={{fontSize:'28px'}}/>
       </a>
       </div> */}

              {/* <div className="mt-4 mb-3 col-12" style={{ display: 'inline-flex', justifyContent: "space-between" }}>
                <a href="https://www.facebook.com/placestovisitIndiaofficial" target="_blank" rel="noopener noreferrer">
                  <FaFacebook style={{ fontSize: '30px' }} />
                </a>
                <a href="https://www.instagram.com/placestovisitindiaofficial/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="mx-2" style={{ fontSize: '28px' }} />
                </a>
                <a href="https://www.youtube.com/@Placestovisitindia-Official" target="_blank" rel="noopener noreferrer">
                  <FaYoutube className="mx-2" style={{ fontSize: '28px' }} />
                </a>
                <a href="https://www.linkedin.com/company/103039021/admin/dashboard" target="_blank" rel="noopener noreferrer">
                  <CiLinkedin className="mx-2" style={{ fontSize: '28px' }} />
                </a>
              </div> */}


              {/* <h5>Write to us: <a href='mailto:info@placestovisitindia.com'>info@placestovisitindia.com</a></h5> */}

            </div>
            {/* <div className='col-lg-1 col-md-6 col-dm-12 mt-3'></div> */}

            {
              data?.slice(0, 2)?.map((res) => {
                return (
                  <>
                    <div className='col-lg-3 col-md-6 col-dm-12 mt-3'>

                      <h5 style={{ fontWeight: '700',color:"#fff"}}>{res?.name}</h5>
                      <ul className='ps-0' style={{ textDecoration: 'none', cursor: "pointer", listStyle: 'none', fontSize: '18px', fontWeight: "500", lineHeight: '38px', color:"#fff" }}>
                        {
                          res?.onlyposts?.map((res, index) => {
                            return (
                              <>

                                <li style={{ cursor: "pointer",fontSize:"16px"}} onClick={() => {
                                  Navigate(`/${res?.post_slug}`);
                                  setUser(true)
                                }}>{res?.post_title}</li>

                              </>
                            )
                          })
                        }
                      </ul>

                    </div>
                  </>
                )
              })
            }

            <div className='col-lg-3 col-md-6 col-dm-12 mt-3' style={{}}>
              <div style={{
              //  marginLeft:"60px"
              }}>

                <h5 style={{ fontWeight: '700',color:"#fff" }}>Quick Links</h5>

                <ul className='ps-0' style={{ textDecoration: 'none', listStyle: 'none', fontSize: '18px', fontWeight: "500", lineHeight: '38px', color:"#fff" }}>
                  <li style={{ cursor: "pointer",fontSize:"16px" }} onClick={() => { Navigate("/AboutUs") }}>About us</li>
                  <li style={{ cursor: "pointer",fontSize:"16px"}} onClick={() => { Navigate("/ContactUs") }}>Contact us</li>
                  <li style={{ cursor: "pointer",fontSize:"16px" }} onClick={() => { Navigate("/privacypolicy") }}>Privacy Policy</li>
                  <li style={{ cursor: "pointer",fontSize:"16px" }} onClick={() => { Navigate("/copyrightpolicy") }}>Copyright Policy</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='mt-5' style={{borderBottom:"1px solid #fff"}}></div>
          <div className='row'>
             <div className='col-lg-8 col-md-6 col-dm-12 mt-3'>
              <h6 style={{color:"#fff"}}>© 2024 Places-to-Visit-India all rights reserved.</h6>
             </div>
             <div className='col-lg-4 col-md-6 col-dm-12 mt-3'></div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer