// import React, { useState, useEffect, useContext } from 'react'
// import './Header.css'
// import { useNavigate,NavLink } from 'react-router-dom';
// import logo from "../../src/Component/Image/Logo.png"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
// import Context from '../Context/Context'
// import { FaFacebook, FaInstagram, FaTwitter, FaX, FaXmarksLines, FaYoutube } from 'react-icons/fa6';
// import x from "./Image/x.jpeg"
// import facebook from "../../src/Component/Image/facebook.png"
// import youtube from "../../src/Component/Image/youtube.png"
// import instagram from "../../src/Component/Image/instagram.png"
// import linkedin from "../../src/Component/Image/linkedin.png"


// const Header = () => {
//   const { user, setUser } = useContext(Context);

//   const [isOpen, setIsOpen] = useState(false);
//   const [Data, setData] = useState([]);

//   const Navigate = useNavigate();

//   const toggleDropdown = (index) => {
//     setIsOpen(index);
//   };


//   useEffect(() => {
//     DataForHeader();
//   }, []);


//   const DataForHeader = () => {
//     // const axios = require('axios');

//     let config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: 'https://api.placestovisitindia.com/api/getmenu',
//       headers: {}
//     };

//     axios.request(config)
//       .then((response) => {
//         console.log(JSON.stringify(response.data), "hhhhhhhhhhh==========>");
//         // let data = response.data
//         setData(response?.data?.data)
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//   }



//   return (
//     <>

//       <div className='container-fluid '>
//         <div className='row'>
//           <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
//             <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
//               <div class="container-fluid">
//                 <img className='logoNew' src={logo} style={{height: 70,objectFit: "cover",cursor:"pointer"}} onClick={()=>{Navigate(`/`)}}/>
//                 {/* <a class="navbar-brand" href="/">Logo</a> */}
//                 <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                   <span class="navbar-toggler-icon"></span>
//                 </button>
//                 <div class="collapse navbar-collapse" id="navbarSupportedContent">
//                   <ul class="navbar-nav m-auto mb-2 mb-lg-0">

//                     {
//                       Data?.slice(0, 5).map((res, index) => {
//                         return (
//                           <React.Fragment key={index}>
//                             <li className="nav-item dropdown" onMouseEnter={()=>toggleDropdown(index)} onMouseLeave={()=>toggleDropdown()}>
//                               <a className="nav-link" role="button" aria-expanded={isOpen} style={{textTransform:"capitalize"}}>
//                                 {res?.name} <FontAwesomeIcon icon={isOpen===index  ? faAngleUp : faAngleDown} />
//                               </a>
//                               <ul className={`dropdown-menu ${isOpen===index ? 'show' : ''}`}>
//                                 {
//                                   res?.onlyposts?.map((res) => {
//                                     return (
//                                       <>
//                                         <li style={{cursor:"pointer"}} onClick={()=>{Navigate(`/${res?.post_slug}`);
//                                           setUser(true)
//                                         }}><h6 className="dropdown-item">{res?.post_name}</h6></li>
//                                       </>
//                                     )
//                                   })
//                                 }

//                               </ul>
//                             </li>
//                           </React.Fragment>
//                         );
//                       })
//                     }

//                     <NavLink to='/blogs'> 
//                         <li class="nav-item">
//                           <a class="nav-link" href="#">Blogs</a>
//                         </li>
//                     </NavLink>
    
//                   </ul>
                  
//                 </div>
                
//               </div>
//               <div className="mt-4 mb-3 hideHeader" style={{ fle,justifyContent: "space-between",marginRight:30}}>
//                 <a href='https://www.facebook.com/placestovisitIndiaofficial'>
//                 <img src={facebook} style={{width:28,height:28,objectFit:"cover",}} />
//                 </a>
//                 <a href='https://www.instagram.com/placestovisitindiaofficial/'>
//                 <img src={linkedin} style={{width:28,height:28,objectFit:"cover",}} />
//                 </a>
//                 <a href="https://www.youtube.com/channel/UC9nYP8zZXGFlEmA2gIS1SVA">
//                 <img src={youtube} style={{width:28,height:28,objectFit:"cover",}} />
//                 </a>
//                 <a href='https://twitter.com/Places2visitind'>
//                   <img src={instagram} style={{width:28,height:28,objectFit:"cover",}} />
//                 </a>
//               </div>

// {/* <div className="social-media-links" style={{ display: 'flex', justifyContent: 'space-between', marginRight: 30, width: '12%' }}>
//       <a href='https://www.facebook.com/placestovisitIndiaofficial'>
//         <img src={facebook} alt="Facebook" style={{ width: 30, height: 30, objectFit: "cover" }} />
//       </a>
//       <a href='https://www.instagram.com/placestovisitindiaofficial/'>
//         <img src={linkedin} alt="LinkedIn" style={{ width: 30, height: 30, objectFit: "cover" }} />
//       </a>
//       <a href="https://www.youtube.com/channel/UC9nYP8zZXGFlEmA2gIS1SVA">
//         <img src={youtube} alt="YouTube" style={{ width: 30, height: 30, objectFit: "cover" }} />
//       </a>
//       <a href='https://www.linkedin.com/company/103039021/admin/dashboard'>
//         <img src={instagram} alt="Instagram" style={{ width: 30, height: 30, objectFit: "cover" }} />
//       </a>
//     </div> */}
//             </nav>
            
//           </div>
//         </div>
//       </div>

//     </>
//   )
// }

// export default Header


import React, { useState, useEffect, useContext } from 'react'
import './Header.css'
import { useNavigate,NavLink } from 'react-router-dom';
import logo from "../../src/Component/Image/Logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Context from '../Context/Context'
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa6';

const Header = () => {
  const { user, setUser } = useContext(Context);

  const [isOpen, setIsOpen] = useState(false);
  const [Data, setData] = useState([]);

  const Navigate = useNavigate();

  const toggleDropdown = (index) => {
    setIsOpen(index);
  };


  useEffect(() => {
    DataForHeader();
  }, []);


  const DataForHeader = () => {
    // const axios = require('axios');

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://api.placestovisitindia.com/api/getmenu',
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data), "hhhhhhhhhhh==========>");
        // let data = response.data
        setData(response?.data?.data)
      })
      .catch((error) => {
        console.log(error);
      });

  }



  return (
    <>

      <div className='container-fluid '>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
            <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
              <div class="container-fluid">
                <img className='logoNew' src={logo} style={{height: 70,objectFit: "cover",cursor:"pointer"}} onClick={()=>{Navigate(`/`)}}/>
                {/* <a class="navbar-brand" href="/">Logo</a> */}
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav m-auto mb-2 mb-lg-0">

                    {
                      Data?.slice(0, 5).map((res, index) => {
                        return (
                          <React.Fragment key={index}>
                            <li className="nav-item dropdown" onMouseEnter={()=>toggleDropdown(index)} onMouseLeave={()=>toggleDropdown()}>
                              <a className="nav-link" role="button" aria-expanded={isOpen} style={{textTransform:"capitalize"}}>
                                {res?.name} <FontAwesomeIcon icon={isOpen===index  ? faAngleUp : faAngleDown} />
                              </a>
                              <ul className={`dropdown-menu ${isOpen===index ? 'show' : ''}`}>
                                {
                                  res?.onlyposts?.map((res) => {
                                    return (
                                      <>
                                        <li style={{cursor:"pointer"}} onClick={()=>{Navigate(`/${res?.post_slug}`);
                                          setUser(true)
                                        }}><h6 className="dropdown-item">{res?.post_name}</h6></li>
                                      </>
                                    )
                                  })
                                }

                              </ul>
                            </li>
                          </React.Fragment>
                        );
                      })
                    }

                    <NavLink to='/blogs'> 
                        <li class="nav-item">
                          <a class="nav-link" href="#">Blogs</a>
                        </li>
                    </NavLink>
    
                  </ul>
                  
                </div>
                
              </div>
              <div className="mt-4 mb-3 hideHeader" style={{ justifyContent: "space-between",marginRight:30}}>
                <a href='https://www.facebook.com/placestovisitIndiaofficial'  target="_blank" rel="noopener noreferrer">
                  <FaFacebook style={{ fontSize: '30px'}} />
                </a>
                <a href='https://www.instagram.com/placestovisitindiaofficial/'  target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="mx-2" style={{ fontSize: '28px',color:"#F95CEE" }} />
                </a>
                <a href="https://www.youtube.com/@Placestovisitindia-Official" target="_blank" rel="noopener noreferrer">
                  <FaYoutube className="mx-2" style={{ fontSize: '28px',color:"#FB2525"  }} />
                </a>
                <a href='https://twitter.com/Places2visitind' target="_blank" rel="noopener noreferrer">
                  <FaTwitter className="mx-2" style={{ fontSize: '28px',color:"#F95CEE"  }} />
                </a>
              </div>
            </nav>
            
          </div>
        </div>
      </div>

    </>
  )
}

export default Header