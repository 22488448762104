import React, { useEffect } from 'react'
import Footer from './Footer';
import Header from './Header';
import About from "./Image/About.jpg"

const Aboutus = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    const divStyle = {
        backgroundImage: `url(${About})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '500px', // Adjust height as needed
        display: 'flex',
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
        position: 'relative', // Ensure absolute positioned elements are relative to this div
        color: '#fff', // Text color
        marginTop:40
    };
    return (
        <div>
            <Header />

            <div className='container-fluid mt-5'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
                        <div className='' style={divStyle}>
                            {/* <h1 className='text-center hhhh' style={{ position: 'absolute', top: '50%', left: '50%', color: '#fff', transform: 'translate(-50%, -50%)' }}>{Data?.parent_blogs?.title}</h1> */}
                            {/* <span className='text-center mt-3 ' style={{position:'absolute', top:'46%', left:'30%', color:'#000' , fontSize:'18px', fontWeight:'500'}}>Explore Destinations & Get Inspired For Your Next Getaway</span> */}
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className='mt-5'> */}
                <div className='mt-5'>
                    <section className='mt-4'>
                        <div className='container px-5 pt-3 hii' style={{ backgroundColor: "#F6E9E9" }}>
                            <div className='row'>

                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <h4 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1', textTransform: "capitalize" }}>
                                        Unveiling the Enchanting Mosaic of India: <a className='active'>PlacesToVisitIndia.com</a> 
                                    </h4>

                                    <div>
                                        <p>
                                            We are <a className='active'>PlacesToVisitIndia.com</a>, a passionate team of travel enthusiasts from diverse backgrounds. United by a love for exploration, we aim to simplify travel experiences for everyone.
                                            {/* <br/> */}
                                            <h4 className='mt-3'>A Nation Reborn for Exploration</h4>
                                            {/* <br/> */}
                                            India is brimming with renewed energy. We, as a nation, are rediscovering the magic of our heritage and the captivating beauty of our landscapes. From ancient wonders to untouched natural sanctuaries, India's tourist destinations are a treasure trove waiting to be explored.

                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='mt-4'>
                        <div className='container px-5 pt-3 hii' style={{ backgroundColor: "#DBECF1" }}>
                            <div className='row'>

                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    {/* <h4 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1', textTransform: "capitalize" }}>
                                        Unveiling the Enchanting Mosaic of India: <a className='active'>PlacesToVisitIndia.com</a> 
                                    </h4> */}

                                    <div>
                                        <p>

                                        

                                           <h4>Our Mission: Unveiling the Jewel of Incredible India</h4> 

                                            Travel and tourism are flourishing globally, and India, with its rich tapestry of history stretching back millennia, is captivating the world's imagination. We envision a future where both domestic and international tourists can explore India with ease, enjoying comfortable stays and delectable cuisine. We want to transform the travel landscape in India by adhering to the highest industry standards.

                                            <h4 className='mt-3'> Beyond Survival: Reclaiming Travel as a Way of Life</h4>

                                            Travel in India transcends mere survival; it's woven into the very fabric of our culture. From historic pilgrimages that encouraged exploration to the vibrant tapestry of communities and traditions, travel fosters unity and understanding.

                                            <h4 className='mt-3'>Embracing the Influx: Preparing for a Tourist Boom</h4>

                                            As India gears up for a surge in international tourism, we recognize the importance of responsible travel practices. Both tourists and locals have a role to play. Travelers can learn valuable lessons about respecting cultural norms and responsible behavior at destinations.
                                            <br/>
                                            PlacesToVisitIndia.com is committed to providing the latest updates and comprehensive information on tourist destinations, empowering travelers to plan their itineraries efficiently and make the most of their experiences.

                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>


                    <section className='mt-4'>
                        <div className='container px-5 pt-3 hii' style={{ backgroundColor: "#F6E9E9" }}>
                            <div className='row'>

                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    
                                    <div>
                                        <p style={{fontWeight:400}}>

                                            <h4> Our Goals: Transforming Travel in India</h4>

                                            Travel and tourism have the potential to not only elevate the lives of Indians but also accelerate our nation's economic growth. Our mission is to make travel an integral part of life for Indians, as essential as early education.
                                           <br/>
                                            PlacesToVisitIndia.com goes beyond promoting established tourist destinations. We aim to shine a spotlight on hidden gems, fostering their potential and empowering local communities. We believe in empowering self-planned travel, breaking the cycle of reliance on travel agents, and allowing local businesses to thrive.
                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            {/* </div> */}
            <div className='mt-3'>

            <Footer />
            </div>
        </div>
    )
}

export default Aboutus;