import React, { useState, useEffect } from 'react'
import Header from '../Component/Header'
import vela from '../Component/Image/Voyageurs.jpg'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import Footer from './Footer'

const Explore = () => {


    const [Data, setData] = useState();
    const [showFullContent, setShowFullContent] = useState(false);



    const navigate = useNavigate();

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };


    useEffect(() => {
        Get_blogs();
    }, []);

    const truncateText = (text, maxLength) => {
        if (!text || text.length <= maxLength) {
            return text;
        }
        const truncated = text.substr(0, maxLength);
        const lastSpace = truncated.lastIndexOf(' ');
        return lastSpace === -1 ? truncated : truncated.substr(0, lastSpace) + '...';
    };


    const Get_blogs = () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch("https://api.placestovisitindia.com/api/blogs", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                window.scrollTo(0, 0);
                console.log(result,"blogs");
                setData(result);
            })
            .catch((error) => console.error(error));
    }


    const divStyle = {
        backgroundImage: `url(${Data?.parent_blogs?.images[0]?.img_path})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '300px', // Adjust height as needed
        position: 'relative', // Ensure absolute positioned elements are relative to this div
        color: '#fff', // Text color
    };

    function removeHtmlTags(html) {
        return html.replace(/<[^>]*>/g, '');
    }
    return (
        <div>
            <Header />

            <div className='container-fluid mt-5'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
                        <div className='' style={divStyle}>
                            <h1 className='text-center hhhh' style={{ position: 'absolute', top: '50%', left: '50%', color: '#fff', transform: 'translate(-50%, -50%)' }}>{Data?.parent_blogs?.title}</h1>
                            {/* <span className='text-center mt-3 ' style={{position:'absolute', top:'46%', left:'30%', color:'#000' , fontSize:'18px', fontWeight:'500'}}>Explore Destinations & Get Inspired For Your Next Getaway</span> */}
                        </div>
                    </div>
                </div>
            </div>




            {/* <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 col-sm-12'>
                        <h2 className='text-center'>Search Results For: <strong className='text-info'>{Data?.parent_blogs?.title} </strong>Things To Do</h2>
                    </div>
                </div>
            </div> */}




            <div className='container my-5 '>
                <div className='row'>
                    {Data?.blogs?.map((res, index) => (
                        <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
                            <div className='card List' style={{cursor:"pointer"}} onClick={() => { navigate(`/blogs/${res.slug}`) }}>
                                <div className='card-header p-0'>
                                    <img src={res?.images[0]?.img_path} alt='image' style={{ width: '100%', height: 300 }} />
                                </div>
                                <div className='card-body mb-4 '>
                                    <h5 className='Handy mb-4'>{res?.title}</h5>
                                    <div className="" style={{}}>
                                        {removeHtmlTags(truncateText(res.description, 120))}
                                        <span className='mb-5'>
                                            <i>
                                                <strong className='text-info' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/blogs/${res.slug}`) }}>Read More</strong>
                                            </i>
                                        </span>
                                    </div>



                                </div>
                            </div>
                        </div>


                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Explore