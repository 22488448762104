import React from 'react';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="title">Coming Soon</h1>
      <div className="countdown">
        <p className="countdown-text">Stay tuned, we're working on something awesome!</p>
        {/* You can add a countdown timer or any other content here */}
      </div>
    </div>
  );
}

export default ComingSoon;
