import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import About from "./Image/Contact.jpg";
import { FaCarAlt, FaMailBulk, FaPhone, FaVoicemail, FaWhatsapp } from 'react-icons/fa';


const Contactus = () => {

    const divStyle = {
        backgroundImage: `url(${About})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '500px', // Adjust height as needed
        display: 'flex',
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
        position: 'relative', // Ensure absolute positioned elements are relative to this div
        color: '#fff', // Text color
        marginTop: 40
    };
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <div>
            <div>
                <Header />

                <div className='container-fluid mt-5'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
                            <div className='' style={divStyle}>
                                {/* <h1 className='text-center hhhh' style={{ position: 'absolute', top: '50%', left: '50%', color: '#fff', transform: 'translate(-50%, -50%)' }}>{Data?.parent_blogs?.title}</h1> */}
                                {/* <span className='text-center mt-3 ' style={{position:'absolute', top:'46%', left:'30%', color:'#000' , fontSize:'18px', fontWeight:'500'}}>Explore Destinations & Get Inspired For Your Next Getaway</span> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div className='mt-5'>
                    <section >
                        <div className='container px-5 hii' style={{ backgroundColor: "#DBECF1", borderRadius: 10, overflow: "hidden" }}>
                            <div className='row'>

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                    <h4 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1', textTransform: "capitalize" }}>
                                        We promise to revert you within 6 hours!
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <div className='mt-5'> */}
                <div className='mt-5'>
                    <section className='mt-4'>
                        <div className='container px-5 pt-3 hii' style={{ backgroundColor: "#F6E9E9" }}>
                            <div className='row'>

                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <h4 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1', textTransform: "capitalize" }}>
                                       Explore India with Confidence
                                    </h4>

                                    <div>
                                        <p>
                                            Embark on your Indian adventure with the assurance of accurate and up-to-date information, courtesy of our dedicated team of experts at Places to Visit India. We recognize that each tourist destination offers a unique experience, varying in ambiance and appeal with each passing season. Leveraging our extensive network, we provide comprehensive updates on crucial factors such as climate conditions, tourist influx, cost fluctuations, and more.
                                            <br/>
                                            Tailored to your preferences and constraints, our services include crafting personalized itineraries tailored to your timeframe and budget. Whether you're uncertain about where to begin or seeking guidance to optimize your journey, our seasoned experts are here to assist. From crafting a meticulous day-by-day itinerary to maximizing your time and resources, we strive to ensure a seamless travel experience.
                                            <br/>
                                            Best of all, our consultation services come at no cost. Feel free to reach out for a brief inquiry about a destination or to start planning your dream trip—our team is here to help you every step of the way.
                                        </p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* </div> */}

                {/* <div>

            
               
            </div> */}

                <div className='mt-5'>
                    <section className='mt-4'>
                        <div className='container px-5 pt-3 hii' style={{ backgroundColor: "#EDE9F6" }}>
                            <div className='row p-5'>
                               <div className='col-4' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                  <div style={{backgroundColor:"red",padding:30,borderRadius:"50%"}}>
                                    <FaMailBulk style={{color:"#fff",fontSize:40}}/>
                                  </div>
                               </div>
                               <div className='col-4' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                  <div style={{backgroundColor:"#25D366",padding:30,borderRadius:"50%"}}>
                                    <FaWhatsapp style={{color:"#fff",fontSize:40}}/>
                                  </div>
                               </div>
                               <div className='col-4' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                  <div style={{backgroundColor:"green",padding:30,borderRadius:"50%"}}>
                                    <FaPhone style={{color:"#fff",fontSize:40}}/>
                                  </div>
                               </div>
                               <div className='col-4 mt-2' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                 <h3>Email Us</h3>
                               </div>
                               <div className='col-4 mt-2' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                 <h3>WhatsApp Us</h3>
                               </div>
                               {/* <div className='col-4 mt' style={{display:"flex",alignItems:"center",justifyContent:"center"}}> */}
                               <div className='col-4 mt-2' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                 <h3>Contact Us</h3>
                               </div>
                               <div className='col-4 mt-2' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                 <h3>Info@placetovisitindia.com</h3>
                               </div>
                               <div className='col-4 mt-2' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                 <h3>+91-9929107926</h3>
                               </div>
                               <div className='col-4 mt-2' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                 <h3>+91-9929107926</h3>
                               </div>
                            </div>
                        </div>
                    </section>

                </div>



                <div className='mt-3'>

                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Contactus