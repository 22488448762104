import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import About from "./Image/Privacy.jpg"
const Privacy = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    const divStyle = {
        backgroundImage: `url(${About})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '500px', // Adjust height as needed
        display: 'flex',
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
        position: 'relative', // Ensure absolute positioned elements are relative to this div
        color: '#fff', // Text color
        marginTop:40
    };
  return (
    <div>
    <Header />

    <div className='container-fluid mt-5'>
        <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
                <div className='' style={divStyle}>
                   
                </div>
            </div>
        </div>
    </div>


    {/* <div className='mt-5'> */}
        <div className='mt-5'>
           

            <section className='mt-4'>
                <div className='container px-5 pt-3 hii' style={{ backgroundColor: "#DBECF1" }}>
                    <div className='row'>

                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            {/* <h4 className='my-4' style={{ fontWeight: '700', textShadow: '1.1px 0px 0px #c1c1c1', textTransform: "capitalize" }}>
                                Unveiling the Enchanting Mosaic of India: <a className='active'>PlacesToVisitIndia.com</a> 
                            </h4> */}

                            <div>
                                <p>

                                <p className='mt-3'>Privacy Policy</p>

This Privacy Policy outlines how Places to Visit India collects, uses, protects, and manages personal information obtained through the website www.placestovisitindia.com.

<p className='mt-3'>Information Collection</p>

We collect personal information through forms on our Website, such as the contact form or newsletter subscription form. The types of personal information we collect may include names and email addresses.
<p className='mt-3'>Use of Information</p>
The personal information collected is used solely for the purpose of responding to inquiries, providing requested services, and improving user experience on our Website. We do not share this information with third parties.

<p className='mt-3'>Data Security</p>
We take appropriate measures to ensure the security of the personal information provided to us and to protect it from unauthorized access, alteration, disclosure, or destruction. User data is stored securely and accessed only by authorized personnel.
<p className='mt-3'>Dispute Resolution</p>
Any disputes arising from the use of our Website or concerning this Privacy Policy shall be resolved in accordance with the laws of Jaipur, India, and the exclusive jurisdiction of the courts in Jaipur shall apply.
<p className='mt-3'>Cookies</p>
We do not use cookies or similar tracking technologies on our Website.
<p className='mt-3'>Data Retention</p>
We do not store user data beyond the scope necessary to fulfill the intended purpose of the information provided. User data provided for inquiries is retained only for as long as necessary to respond to the inquiry effectively.
<p className='mt-3'>Changes to this Privacy Policy</p>
We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on the Website.
<p className='mt-3'>Contact Us</p>
If you have any questions or concerns about this Privacy Policy or the handling of your personal information, please contact us at contact@placestovisitindia.com.

                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </section>


            
        </div>
    {/* </div> */}
    <div className='mt-3'>

    <Footer />
    </div>
</div>
  )
}

export default Privacy